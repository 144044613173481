import React from 'react'
import { Link } from 'gatsby'

import Logo from '../img/footer-logo.svg'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="container">
          <div className="columns">
            <div className="column is-4">
              <img src={Logo} className="footer-logo" /> <br />
              <p className="footer-text">
              A platform for interested buyers who are eyeing wholesale deals. We enable Filipinos to form a group with random buyers to meet the required number of orders, letting them "Chip In" when purchasing products at a cheaper, wholesale price.
              </p>
            </div>
            <div className="column is-6 is-offset-2">
              <div className="columns">
                <div className="column is-4">
                  <p>
                    <Link to="/#" className="navbar-item">
                      Home
                    </Link>
                  </p>
                  <p>
                    <Link to="/#faqs" className="navbar-item">
                      FAQs
                    </Link>
                  </p>
                  <p>
                    <Link to="/contact" className="navbar-item">
                      Contact
                    </Link>
                  </p>
                  <p>
                    <Link to="/#" className="navbar-item">
                      We're Hiring
                    </Link>
                  </p>
                </div>
                <div className="column is-4">
                  <p>
                    <Link to="/blog" className="navbar-item">
                      Blog
                    </Link>
                  </p>
                  <p>
                    <a href="https://www.facebook.com/cheapin.coph" className="navbar-item" target="_blank">
                      Facebook
                    </a>
                  </p>
                  <p>
                    <a href="https://www.instagram.com/cheapin.co" className="navbar-item">
                      Instagram
                    </a>
                  </p>
                  <p>
                    <Link to="/#" className="navbar-item">
                      Twitter
                    </Link>
                  </p>
                </div>
                <div className="column is-4">
                  <p>
                    <Link to="/terms" className="navbar-item">
                      Terms
                    </Link>
                  </p>
                  <p>
                    <Link to="/privacy" className="navbar-item">
                      Privacy
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

    )
  }
}

export default Footer
